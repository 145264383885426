import React, { useEffect, useRef, useState } from "react";
import { Link, Outlet } from "react-router-dom";

import { ErrorBoundary } from "./ErrorBoundary";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { LamfoApiUserRepository } from "../../infrastructure/LamfoApiUserRepository";
import { LamfoApiUserDataResponse } from "../../infrastructure/LamfoApiResponses";
import { Image, ListGroup, NavDropdown, Offcanvas, Spinner, Stack } from "react-bootstrap";
import { FaBell, FaCalendarAlt } from 'react-icons/fa';
import styles from "./layout.module.scss";
import UserContext from "../../contexts/UserContext";
import { createEmptyUserNotificationsResponse } from "../../infrastructure/LamfoEmptyThings";
import Skeleton from "react-loading-skeleton";
import { EventsCalendar } from "../../components/eventsCalendar/EventsCalendar";

const reporitory = new LamfoApiUserRepository();

export function Layout() {
	const [userDataResponse, setUserDataResponse] = useState<LamfoApiUserDataResponse>();
	const [expanded, setExpanded] = useState(false);
	const [notifications, setNotifications] = useState(createEmptyUserNotificationsResponse());
	const [notificationsPage, setNotificationsPage] = useState(1);
	const [showNotifications, setShowNotifications] = useState(false);
	const [showTeamCalendar, setShowTeamCalendar] = useState(false);
	const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
	const elementRef = useRef(null);

	const handleSelect = () => {
		setExpanded(false);
	};

	const fetchUserNotifications = () => {
		if (!userDataResponse || !userDataResponse.success) {
			return;
		}
		if (notificationsPage == 1 && showNotifications) {
			return;
		}
		setIsLoadingNotifications(true);
		reporitory
			.getUserNotifications(notificationsPage)
			.then((userNotifications) => {
				userNotifications.data = notifications.data.concat(userNotifications.data);
				const notificationsImagesUrls = userNotifications.data.map(notification => notification.image_url);
				preloadImages(notificationsImagesUrls)
					.then(() => {
						setIsLoadingNotifications(false);
						setNotifications(userNotifications);
					})
					.catch(() => {
						setIsLoadingNotifications(false);
						setNotifications(userNotifications);
					});
			})
			.catch((error) => {
				console.log(error);
				setIsLoadingNotifications(false);
			});
	};

	const getDaysDifference = (dateString: string) => {
		const givenDate = new Date(dateString);
		const today = new Date();
	
		// Resetear horas, minutos, segundos y milisegundos para comparar solo fechas
		givenDate.setHours(0, 0, 0, 0);
		today.setHours(0, 0, 0, 0);
	
		const timeDifference = today.getTime() - givenDate.getTime();
		const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
	
		// Si la diferencia es 0, significa que la fecha es hoy
		if (dayDifference === 0) {
			return "Hoy.";
		}
	
		// Si la diferencia es de menos de un mes (30 días)
		if (dayDifference < 30) {
			return `Hace ${dayDifference} días.`;
		}
	
		// Calcular la diferencia en meses
		const currentYear = today.getFullYear();
		const currentMonth = today.getMonth(); // Los meses en JS van de 0 a 11
		const givenYear = givenDate.getFullYear();
		const givenMonth = givenDate.getMonth();
	
		const monthDifference = (currentYear - givenYear) * 12 + (currentMonth - givenMonth);
	
		// Si fue el mes pasado
		if (monthDifference === 1) {
			return "El mes pasado.";
		}
	
		// Si la diferencia es de varios meses pero menos de un año
		if (monthDifference < 12) {
			return `Hace ${monthDifference} meses.`;
		}
	
		// Si la diferencia es de más de un año
		return "Hace más de un año.";
	}
	
	useEffect(() => {
		fetchUserNotifications();
	}, [notificationsPage]);

	useEffect(() => {
		reporitory
			.getUserData()
			.then((userDataResponse) => {
				setUserDataResponse(userDataResponse);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [reporitory]);

	useEffect(() => {
		if (!showNotifications) {
			fetchUserNotifications();
			const intervalId = setInterval(fetchUserNotifications, 5 * 60 * 1000);
		
			return () => clearInterval(intervalId);
		}
	}, [reporitory, showNotifications, userDataResponse]);

	useEffect(() => {
        let observer : IntersectionObserver | null = null;

        if (showNotifications) {
            const timer = setTimeout(() => {
                observer = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (entry.isIntersecting) {
                                setNotificationsPage(notificationsPage + 1);
                            }
                        });
                    },
                    { threshold: 1 }
                );

                const currentElement = elementRef.current;
                if (currentElement) {
                    observer.observe(currentElement);
                }
            }, 100);

			return () => {
                clearTimeout(timer);
                if (observer) {
                    observer.disconnect();
                }
            };
        }
    }, [showNotifications, notificationsPage]);

	const handleShowNotifications = () => setShowNotifications(true);
	const handleCloseNotifications = () => {
		setShowNotifications(false);
		setNotifications(createEmptyUserNotificationsResponse());
		setNotificationsPage(1);
		reporitory
			.putMarkAsReadUserNotifications()
			.then(() => {
			})
			.catch((error) => {
				console.log(error);
			});
	}

	const handleShowTeamCalendar = () => setShowTeamCalendar(true);
	const handleCloseTeamCalendar = () => setShowTeamCalendar(false);

	const preloadImages = (imageUrls: string[]) => {
		return Promise.all(
			imageUrls.map((url) => {
				return new Promise((resolve, reject) => {
					const img = document.createElement('img');
					img.src = url;
					img.onload = resolve;
					img.onerror = reject;
				});
			})
		);
	};

	return (
		<React.Fragment>
			<Navbar
				expanded={expanded}
				onToggle={setExpanded}
				bg="primary"
				variant="dark"
				expand="lg"
				sticky="top"
			>
				<Container>
					<Navbar.Brand href={process.env.REACT_APP_LAMFO_OLD_URL}>
						<Image
							alt=""
							src="/img/logo.svg"
							className={'d-inline-block align-top ' + styles.logo}
						/>
						<span className="navbartitle m-1">LAMFO</span>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="me-auto">
							{ (!userDataResponse || !userDataResponse.success)
								?
								<Nav.Link href={process.env.REACT_APP_LAMFO_OLD_URL + 'loginreq.php'}>Login</Nav.Link>
								:
								''
							}
							<Link to="/calendar" onClick={handleSelect} className="nav-link">Calendario</Link>
							<NavDropdown title="Equipos" id="mercado-nav-dropdown">
								<Link to="/team" onClick={handleSelect} className="dropdown-item">Planteles</Link>
								<Link to="/review" onClick={handleSelect} className="dropdown-item">Revisión</Link>
								<Link to="/changes" onClick={handleSelect} className="dropdown-item">Cambios</Link>
            				</NavDropdown>
							<NavDropdown title="Managers" id="mercado-nav-dropdown">
								<Link to="/ranking" onClick={handleSelect} className="dropdown-item">Ranking</Link>
								<NavDropdown.Item
									href={process.env.REACT_APP_LAMFO_OLD_URL + 'objetivos.php'}
								>
									Objetivos
								</NavDropdown.Item>
            				</NavDropdown>
							<NavDropdown title="Mercado" id="mercado-nav-dropdown">
								{ (userDataResponse && userDataResponse.success)
									? 
									<NavDropdown.Item
										href={process.env.REACT_APP_LAMFO_OLD_URL + 'mimercado.php'}
									>
										Mi mercado
									</NavDropdown.Item>
									: ''
								}
								<NavDropdown.Item
									href={process.env.REACT_APP_LAMFO_OLD_URL + 'transferencias.php'}
								>
									Traspasos
								</NavDropdown.Item>
								<NavDropdown.Item
									href={process.env.REACT_APP_LAMFO_OLD_URL + 'exterior.php'}
								>
									Exterior
								</NavDropdown.Item>
								<NavDropdown.Item
									href={process.env.REACT_APP_LAMFO_OLD_URL + 'subastas.php'}
								>
									Subastas
								</NavDropdown.Item>
                				<Link to="/free-players" onClick={handleSelect} className="dropdown-item">Libres</Link>
            				</NavDropdown>
							<Link to="/economy" onClick={handleSelect} className="nav-link">Economía</Link>
							<Link to="/rules" onClick={handleSelect} className="nav-link">Reglamento</Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
				{ (userDataResponse && userDataResponse.success) ?
					<Container>
						<div className={styles.loggedteamdata}>
						<div
								className={'d-inline-block position-relative mr-2 ' + styles.notificationdiv}
								onClick={handleShowTeamCalendar}
							>
								<FaCalendarAlt className={styles.iconbar} />
							</div>
							<div
								className={'d-inline-block position-relative mr-2 ' + styles.notificationdiv}
								onClick={handleShowNotifications}
							>
								<FaBell className={styles.iconbar} />
								{
									(notifications.total_new > 0) &&
										<span
											className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
											style={{ fontSize: '10px' }}
										>
											{notifications.total_new}
										</span>
								}
							</div>
							<Link to={'/team/' + userDataResponse.team.code}>
								<Image
									src={userDataResponse.team.shield}
									className={styles.loggedshield}
								/>
								{userDataResponse.team.name}
							</Link>
						</div>
					</Container>
					:
					''
				}
			</Navbar>
			<ErrorBoundary>
				<UserContext.Provider value={userDataResponse ?? {} as LamfoApiUserDataResponse}>
					<Container fluid className={styles.appcontainer}>
						<Outlet />
					</Container>
				</UserContext.Provider>
			</ErrorBoundary>
			<Offcanvas
				style={{ maxWidth: '90%' }}
				show={showNotifications}
				onHide={handleCloseNotifications}
			>
				<Offcanvas.Header
					closeButton
					className="p-2"
				>
					<Offcanvas.Title>
							NOTIFICACIONES
					</Offcanvas.Title>
				</Offcanvas.Header>
        		<Offcanvas.Body
					className="p-2"
				>
					<ListGroup>
						{
							notifications.data.map((notification, index) => (
								<ListGroup.Item
									className={styles.notificationitem + ' ' + (notification.new && styles.unreadnotificationitem)}
									action
									key={index}
									href={notification.link + '#' + index}
								>	
									<Stack direction="horizontal">
										<Image
											src={notification.image_url}
											onError={({ currentTarget }) => {
												currentTarget.onerror = null;
												currentTarget.src="/img/avatar.png";
											}}
										/>	
										<span>
											{notification.text}
											<span className="ms-1 fw-normal fst-italic">
												{getDaysDifference(notification.date)} 
											</span>
										</span>
									</Stack>
								</ListGroup.Item>
							))
						}
						{ (isLoadingNotifications && notificationsPage > 1) && Array.from({ length: 30 }).map((_, index) => (
								<ListGroup.Item
									className={styles.notificationitem}
									variant="secondary"
									key={index}
								>
									
									<Stack direction="horizontal">
										<Image
											src="/img/avatar.png"
											onError={({ currentTarget }) => {
												currentTarget.onerror = null;
												currentTarget.src="/img/avatar.png";
											}}
										/>	
										<span>
											<Skeleton width={150} />
										</span>
									</Stack>
								</ListGroup.Item>
						)) }
						<ListGroup.Item
							className={styles.notificationitem + ' text-center'}
							variant="secondary"
							key="-1"
						>
							{
								notifications.pagination.total_pages == notificationsPage
									? '-'
									:  <Spinner ref={elementRef} animation="border" size="sm" />
							}
						</ListGroup.Item>
					</ListGroup>
				</Offcanvas.Body>
			</Offcanvas>
			<Offcanvas
				style={{ maxWidth: '90%' }}
				show={showTeamCalendar}
				onHide={handleCloseTeamCalendar}
			>
				<Offcanvas.Header
					closeButton
					className="p-2"
				>
					<Offcanvas.Title>
							PARTIDOS
					</Offcanvas.Title>
				</Offcanvas.Header>
        		<Offcanvas.Body
					className="p-2"
				>
					<EventsCalendar teamCode={userDataResponse?.success ? userDataResponse.team.code : undefined} showOnlyWithMatch />
				</Offcanvas.Body>
			</Offcanvas>
		</React.Fragment>
	);
}
