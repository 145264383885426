import {
	LamfoApiTeamResponse,
	LamfoApiTeamReviewResponse,
	LamfoApiTeamsListResponse,
	Team,
	PlayerReview,
	Division
} from "./LamfoApiResponses";

export class LamfoApiTeamRepository {
	private readonly apiUrl = process.env.REACT_APP_LAMFO_API_URL;
	private readonly getTeamListEndpoint = "$apiUrl/public/teams/$division";
	private readonly getTeamEndpoint = "$apiUrl/public/team/$teamCode";
	private readonly getTeamReviewEndpoint = "$apiUrl/public/review/$teamCode";

	async getTeamsList(division: string): Promise<LamfoApiTeamsListResponse> {
		const repositoryRequest = fetch(
			this.getTeamListEndpoint.replace("$apiUrl", this.apiUrl ?? "").replace("$division", division),
			{ headers: {} }
		);

		return repositoryRequest
			.then((response) => response.json())
			.then((divisions) => {
				return {
					divisions: divisions as Division[],
				};
			});
	}

	async getTeam(teamCode: string): Promise<LamfoApiTeamResponse> {
		const repositoryRequest = fetch(
			this.getTeamEndpoint.replace("$apiUrl", this.apiUrl ?? "").replace("$teamCode", teamCode),
			{ credentials: "include" }
		);

		return repositoryRequest
			.then((response) => response.json())
			.then((team) => {
				return {
					team: team as Team,
				};
			});
	}

	async getTeamReview(teamCode: string): Promise<LamfoApiTeamReviewResponse> {
		const repositoryRequest = fetch(
			this.getTeamReviewEndpoint.replace("$apiUrl", this.apiUrl ?? "").replace("$teamCode", teamCode),
			{ headers: {} }
		);

		return repositoryRequest
			.then((response) => response.json())
			.then((reviews) => {
				return {
					reviews: reviews as PlayerReview[],
				};
			});
	}
}
