import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";

import { LamfoApiTeamResponse } from "../../infrastructure/LamfoApiResponses";
import { LamfoApiTeamRepository } from "../../infrastructure/LamfoApiTeamRepository";
import styles from "./teamdetail.module.scss";
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import { Col, Row, Stack, Tab, Tabs } from "react-bootstrap";
import { Loader } from "../../components/loader/Loader";
import { PlayersList } from "../../components/playersList/PlayersList";

const reporitory = new LamfoApiTeamRepository();

export function TeamDetail() {
	const { teamCode } = useParams() as { teamCode: string };
	const [teamResponse, setTeamResponse] = useState<LamfoApiTeamResponse>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (teamCode) {
			setIsLoading(true);
			reporitory
				.getTeam(teamCode)
				.then((teamResponse) => {
					setTeamResponse(teamResponse);
					setIsLoading(false);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [reporitory, teamCode]);

	return (
		<React.Fragment>
			{isLoading && <Loader />}
			{
				!isLoading && teamResponse &&
				<Tabs
					defaultActiveKey="staff"
					id="justify-tab-example"
					className={styles.maintab}
					justify
					variant="pills"
				>
					<Tab eventKey="team" title="Club" className="text-center">
						En construcción
					</Tab>
					<Tab eventKey="staff" title="Plantel">
						<PlayersList
							teamCode={teamCode}
							players={teamResponse ? teamResponse.team.staff : []}
						/>
						<Card className="mb-3">
							<Card.Footer>
								<div className={styles.footertext}>Cantidad de extranjeros: {teamResponse.team.foreigns}</div>
							</Card.Footer>
						</Card>
					</Tab>
					<Tab eventKey="manager" title="Manager" className="text-center">
						<Card className="mb-3">
							<Card.Header className={styles.cardheader}>
								<Row>
									<Col className="col-3 p-0 text-center">
										<Image
											src={teamResponse?.team.manager.avatar ?? "/img/avatar.png"}
											roundedCircle
											className={styles.avatar}
											onError={({ currentTarget }) => {
												currentTarget.onerror = null;
												currentTarget.src="/img/avatar.png";
											}}
										/>
									</Col>
									<Col className="col-8 p-0">
										<div className={styles.playertext + ' ' + styles.playersurname}>
											{teamResponse?.team.manager.name}
										</div>
									</Col>
								</Row>			
							</Card.Header>
							<Card.Body className="p-0">
								<Row>
									<Col className="p-3 m-2">
										<Stack direction="horizontal" gap={1}>
											<div className={styles.managerdata}>contacto</div>
											<a href={'https://wa.me/' + teamResponse?.team.manager.phone} target="_blank">
												<Image src="/img/whatsapp.png" className={styles.icon} />
											</a>
											<a href={'mailto:' + teamResponse?.team.manager.email} target="_blank">
												<Image src="/img/email.png" className={styles.icon} />
											</a>
										</Stack>
										<Stack direction="horizontal" gap={2}>
											<div className={styles.managerdata}>títulos</div>
											<div className={styles.managerdata + ' ' + styles.statnumber}>Proximamente</div>
										</Stack>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Tab>
					<Tab eventKey="achievements" title="Vitrina" className="text-center">
						En construcción
					</Tab>
				</Tabs>
			}
		</React.Fragment>
	);
}
