import React from "react";

import { Button } from "react-bootstrap";
import { LamfoApiSeasonRepository } from "../../infrastructure/LamfoApiSeasonRepository";

const reporitory = new LamfoApiSeasonRepository();

export function Registration() {
	const handleRegistration = () => {
		reporitory
			.putUserRegister()
			.then(() => {
				alert('Se ha realizado el registro correctamente.');
				window.location.href = process.env.REACT_APP_LAMFO_OLD_URL ?? '/';

			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<React.Fragment>
			<h1 className="my-4">Inscripción en la temporada</h1>
            <p>Para participar en la siguiente temporada de LAMFO, confirme su inscripción presionando el siguiente botón.</p>
            <Button variant="primary" className="my-2" onClick={handleRegistration}>Inscribirse</Button>
		</React.Fragment>
	);
}
