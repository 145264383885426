import React, { useEffect, useRef, useState } from "react";

import { LamfoApiPlayersChangesResponse } from "../../infrastructure/LamfoApiResponses";
import styles from "./changes.module.scss";
import 'react-loading-skeleton/dist/skeleton.css'
import Image from 'react-bootstrap/Image';
import { Pagination, Table } from "react-bootstrap";
import { TeamSelect } from "../../components/teamSelect/TeamSelect";
import { LamfoApiPlayerRepository } from "../../infrastructure/LamfoApiPlayerRepository";
import { createEmptyPlayersChangesResponse } from "../../infrastructure/LamfoEmptyThings";
import Skeleton from "react-loading-skeleton";
import { useSearchParams } from "react-router-dom";

const reporitory = new LamfoApiPlayerRepository();

export function Changes() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [teamCode, setTeamCode] = useState<string>(searchParams.get('team') || '');
	const [teamCodePrev, setTeamCodePrev] = useState<string>(searchParams.get('team') || '');
	const [page, setPage] = useState<number>(parseInt(searchParams.get('page') || '1'));
	const [playersChangesResponse, setPlayersChangesResponse] = useState<LamfoApiPlayersChangesResponse>(createEmptyPlayersChangesResponse());
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		const params = new URLSearchParams();

		if (teamCode !== '') {
			params.append('team', teamCode);
		}

		if (page > 1) {
			params.append('page', page.toString());
		}

		setSearchParams(params);
	}, [teamCode, page]);

	useEffect(() => {
		if (teamCode !== teamCodePrev && page > 1) {
			setPage(1);
		} else {
			setIsLoading(true);
			window.scrollTo({
				top: 0,
			});
			reporitory
				.getPlayerChanges(
					teamCode,
					page
				)
				.then((playersChangesResponse) => {
					setPlayersChangesResponse(playersChangesResponse);
					const shieldsUrls = playersChangesResponse.data.map(change => change.team_shield);
					preloadImages(shieldsUrls)
						.then(() => {
							setIsLoading(false);
						})
						.catch(() => {
							setIsLoading(false);
						});
				})
				.catch((error) => {
					alert(`${error} No hay elementos para mostrar`);
					setPlayersChangesResponse(createEmptyPlayersChangesResponse());
					setIsLoading(false);
				});
		}
		setTeamCodePrev(teamCode);
	}, [teamCode, page]);

	const preloadImages = (imageUrls: string[]) => {
		return Promise.all(
			imageUrls.map((url) => {
				return new Promise((resolve, reject) => {
					const img = document.createElement('img');
					img.src = url;
					img.onload = resolve;
					img.onerror = reject;
				});
			})
		);
	};

	return (
		<React.Fragment>
			<h1 className="text-center mt-3">Atributos</h1>
				<TeamSelect
					onChange={e => setTeamCode(e.target.value)}
					division="human"
					selectedTeam={teamCode}
					allTeamsOption={true}
				/>
			{isLoading &&
				<React.Fragment>
					<Table striped size="sm" bordered hover>
						<tbody>
							{ Array.from({ length: 30 }).map((_, index) => (
								<tr key={index}>
									<td className="text-center"><Skeleton width={28} /></td>
									<td className="text-center"><Skeleton width={14} /></td>
									<td><Skeleton width={50} /></td>
									<td className="text-center"><Skeleton width={18} /></td>
									<td className="text-center"><Skeleton width={18} /></td>
									<td className="text-center"><Skeleton width={14} /></td>
									<td className="text-center"><Skeleton width={18} /></td>
								</tr>
							))}
						</tbody>
					</Table>
				</React.Fragment>
			}
			{!isLoading &&
				<React.Fragment>
					<Table striped size="sm" bordered hover>
						<tbody>
							{playersChangesResponse.data.map((change, index) => (
								<tr key={index}>
									<td className="text-center">
										{new Date(change.date).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit' })}
									</td>
									<td className="text-center">
										<Image
											src={change.team_shield ?? '/img/shield.png'}
											className={styles.teamlogo}
											alt={change.team_name}
											onError={({ currentTarget }) => {
												currentTarget.onerror = null;
												currentTarget.src="/img/shield.png";
											}}
										/>
									</td>
									<td className={styles.playername}>{change.surname} {change.name[0]}.</td>
									<td className="text-center">{change.attribute}</td>
									<td className="text-center">{change.before}</td>
									<td className="text-center">
										<Image
											src={ 'img/arrow-' + (change.attribute == 'POS' ? 'change' : (change.after > change.before ? 'up' : 'down')) + '.png' }
											className={styles.arrow}
										/>
									</td>
									<td className="text-center">{change.after}</td>
								</tr>
							))}
						</tbody>
					</Table>
					<Pagination className="justify-content-center">
						{ page > 1 && <Pagination.Prev onClick={() => setPage(page - 1)} /> }
						<Pagination.Item>Página {page}</Pagination.Item>
						{ page < playersChangesResponse.pagination.total_pages && <Pagination.Next onClick={() => setPage(page + 1)} /> }
					</Pagination>
				</React.Fragment>
			}
		</React.Fragment>
	);
}
