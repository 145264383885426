import { LamfoApiEmptyResponse } from "./LamfoApiResponses";

export class LamfoApiSeasonRepository {
	private readonly apiUrl = process.env.REACT_APP_LAMFO_API_URL;
	private readonly getSeasonRegisterEndpoint = "$apiUrl/private/registration";

	async putUserRegister(): Promise<LamfoApiEmptyResponse> {
		const repositoryRequest = fetch(
			this.getSeasonRegisterEndpoint.replace("$apiUrl", this.apiUrl ?? ""),
			{
				method: "PUT",
				credentials: "include"
			}
		);

		return repositoryRequest
			.then((response) => response);
	}
}
