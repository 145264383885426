import React, { ReactNode, useEffect, useState } from "react";
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import styles from "./regulation.module.scss";

export function Regulation() {
    const [rules, setRules] = useState<string>('');

    useEffect(() => {
		const fetchMarkdown = async () => {
            const response = await fetch('rules.md');
            const text = await response.text();
            setRules(text);
        };
      
        fetchMarkdown();
	}, []);

    const generateSlug = (node: ReactNode) => {
        let str = node ? node.toString().replace(/^\s+|\s+$/g, "") : '';
        str = str.toLowerCase();
        str = str
          .replace(/[^a-z0-9 -]/g, "")
          .replace(/\s+/g, "-")
          .replace(/-+/g, "-");
        
        return str;
    };

	return (
		<React.Fragment>
			<Markdown
                className={styles.rules}
                remarkPlugins={[[remarkGfm, {singleTilde: false}]]}
                components={{
                    h2: ({ node, ...props }) => (
                        <h2 id={generateSlug(props.children)} {...props}></h2>
                    ),
                }}
            >
                {rules}
            </Markdown>
		</React.Fragment>
	);
}
