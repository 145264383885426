import {
	Team,
	LamfoApiUserDataResponse,
	LamfoApiUserNotificationsResponse,
	UserNotification,
	Pagination,
	LamfoApiEmptyResponse,
	LamfoApiManagersRankingResponse,
	ManagerRankingPoints
} from "./LamfoApiResponses";

export class LamfoApiUserRepository {
	private readonly apiUrl = process.env.REACT_APP_LAMFO_API_URL;
	private readonly getUserDataEndpoint = "$apiUrl/private/user/logged-user-data";
	private readonly getUserNotificationsEndpoint = "$apiUrl/private/user/notifications";
	private readonly putMarAsReadUserNotificationsEndpoint = "$apiUrl/private/user/notifications/read";
	private readonly getManagersRankingEndpoint = "$apiUrl/public/ranking";

	async getUserData(): Promise<LamfoApiUserDataResponse> {
		const repositoryRequest = fetch(
			this.getUserDataEndpoint.replace("$apiUrl", this.apiUrl ?? ""),
			{ credentials: "include" }
		);

		return repositoryRequest
			.then((response) => response.json())
			.then((userData) => {
				return {
					success: userData.success as boolean,
					admin: userData.admin as boolean,
					team: userData.team as Team,
				};
			});
	}

	async getUserNotifications(page: number): Promise<LamfoApiUserNotificationsResponse> {
		const repositoryRequest = fetch(
			this.getUserNotificationsEndpoint.replace("$apiUrl", this.apiUrl ?? "") + `?page=${page}`,
			{ credentials: "include" }
		);

		return repositoryRequest
			.then((response) => response.json())
			.then((userNotifications) => {
				return {
					data: userNotifications.data as UserNotification[],
					pagination: userNotifications.pagination as Pagination,
					total_new: userNotifications.total_new as number,
				};
			});
	}

	async putMarkAsReadUserNotifications(): Promise<LamfoApiEmptyResponse> {
		const repositoryRequest = fetch(
			this.putMarAsReadUserNotificationsEndpoint.replace("$apiUrl", this.apiUrl ?? ""),
			{
				method: "PUT",
				credentials: "include"
			}
		);

		return repositoryRequest
			.then((response) => response);
	}

	async getManagerRanking(limit?: number): Promise<LamfoApiManagersRankingResponse> {
		const repositoryRequest = fetch(
			this.getManagersRankingEndpoint.replace("$apiUrl", this.apiUrl ?? "") + (limit ? `?limit=${limit}` : ""),
			{ headers: {} }
		);

		return repositoryRequest
			.then((response) => response.json())
			.then((ranking) => {
				return {
					ranking: ranking as ManagerRankingPoints[],
				};
			});
	}
}
