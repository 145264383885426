import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import styles from "./match.module.scss";
import { Button, Col, Image, Modal, ProgressBar, Row, Stack, Toast } from "react-bootstrap";
import { GameMatchAction, GameMatchPlayer, LamfoApiGameMatchResponse } from "../../infrastructure/LamfoApiResponses";
import { LamfoApiGameMatchRepository } from "../../infrastructure/LamfoApiGameMatchRepository";
import { MatchTeamLineup } from "./MatchTeamLineup";
import { Loader } from "../../components/loader/Loader";

const reporitory = new LamfoApiGameMatchRepository();

export function Match() {
	const defaultColors = {
		primary: '#122550',
		secondary: 'white',
	};
	const [gameMatchResponse, setGameMatchResponse] = useState<LamfoApiGameMatchResponse>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { matchId } = useParams() as { matchId: string };
	const [isClockRunning, setIsClockRunning] = useState<boolean>(false);
	const [isPlayButtonDisabled, setIsPlayButtonDisabled] = useState<boolean>(false);
	const [isPenaltyShootout, setIsPenaltyShootout] = useState<boolean>(false);
	const [isMatchFinished, setIsMatchFinished] = useState<boolean>(false);
	const [matchHalf, setMatchHalf] = useState<number>(1);
	const [matchTime, setMatchTime] = useState<number>(0);
	const [matchScore, setMatchScore] = useState<number[]>([0, 0]);
	const [penaltyShootoutScore, setPenaltyShootoutScore] = useState<number[]>([0, 0]);
	const [matchActionLine, setMatchActionLine] = useState<string>('');
	const [matchActionLineTextColor, setMatchActionLineTextColor] = useState<string>(defaultColors.secondary);
	const [matchActionLineBackgroundColor, setMatchActionLineBackgroundColor] = useState<string>(defaultColors.primary);
	const isActionInProgressRef = useRef(false);
  	const intervalRef = useRef<number | undefined>(undefined);
	const [intervalTime, setIntervalTime] = useState<number>(1000);
	const [showSpeedModal, setShowSpeedModal] = useState(false);
	const [shouldShowSpeedModal, setShouldShowSpeedModal] = useState(false);
	const [speedPercentage, setspeedPercentage] = useState<number>(50);
	const [showActionModal, setShowActionModal] = useState(false);
	const [actionModalTitle, setActionModalTitle] = useState<string>('');
	const [actionModalPlayer, setActionModalPlayer] = useState<GameMatchPlayer>();
	const [textViewMode, setTextViewMode] = useState<boolean>(false);
	const [playersAvatar, setPlayersAvatar] = useState<ImageMap>({});
	
	type ImageMap = {
		[key: string]: HTMLImageElement;
	};

	useEffect(() => {
		if (shouldShowSpeedModal) {
			updateSpeedPercentage();
			setShowSpeedModal(true);
			setTimeout(() => {
				setShowSpeedModal(false);
			}, 300);
		}
	}, [intervalTime]);

	useEffect(() => {
			setTimeout(() => {
				setShowActionModal(false);
			}, intervalTime);
	}, [showActionModal]);
	
	const startClock = () => {
		setIsClockRunning(true);
	};
	
	const stopClock = () => {
		setIsClockRunning(false);
	};

	const switchViewMode = () => {
		stopClock();
		setTextViewMode((currentTextViewMode) => !currentTextViewMode);
	}

	const updateMatchActionLine = (textLine: string, teamCode: string | undefined) => {
		if (teamCode !== undefined) {
			setMatchActionLineTextColor(
				gameMatchResponse?.game_match.teams.find(
					(team) => team.code === teamCode
				)?.colors.secondary ?? defaultColors.secondary
			);
			setMatchActionLineBackgroundColor(
				gameMatchResponse?.game_match.teams.find(
					(team) => team.code === teamCode
				)?.colors.primary ?? defaultColors.primary
			);
		} else {
			setMatchActionLineTextColor(defaultColors.secondary);
			setMatchActionLineBackgroundColor(defaultColors.primary);
		}
		setMatchActionLine(textLine);
	};

	const increaseSpeed = () => {
		if (intervalTime > 200) {
			setShouldShowSpeedModal(true);
			setIntervalTime((prevIntervalTime) => prevIntervalTime - 200);
		}
	};

	const decreaseSpeed = () => {
		if (intervalTime < 2200) {
			setShouldShowSpeedModal(true);
			setIntervalTime((prevIntervalTime) => prevIntervalTime + 200);
		}
	};

	const updateSpeedPercentage = () => {
		setspeedPercentage((2200 - intervalTime) / 20);
	};

	const processAction = (action: GameMatchAction) => {
		console.log('ACCION A PROCESAR', action);
		const teamIndex = action.team
			? gameMatchResponse?.game_match.teams.findIndex((team) => team.code === action.team) || 0
			: 0;

		const playerIndex = action.team
			? gameMatchResponse?.game_match.teams[teamIndex].lineup.findIndex((player) => player.player === action.player) || 0
			: 0;

		switch (action.type) {
			case 'chance':
			case 'foul':
			case 'goal-cancelled':
			case 'penalty-failed':
			case 'penalty-shootout-fail':
				break;
			case 'goal':
			case 'penalty-goal':
				if (teamIndex !== undefined && teamIndex !== -1) {
					setMatchScore((prevScore) => {
						const newScore = [...prevScore];
						newScore[teamIndex] = newScore[teamIndex] + 1;
						return newScore;
					});
				}
				if (gameMatchResponse?.game_match.teams[teamIndex].lineup[playerIndex]) {
					gameMatchResponse.game_match.teams[teamIndex].lineup[playerIndex].goals++;
					setActionModalPlayer(gameMatchResponse?.game_match.teams[teamIndex].lineup[playerIndex]);
				}
				setActionModalTitle('GOOOOOL');
				setShowActionModal(true);
				updateMatchActionLine('SE REANUDA EL JUEGO', undefined);
				break;
			case 'yellow-card':
				if (gameMatchResponse?.game_match.teams[teamIndex].lineup[playerIndex]) {
					gameMatchResponse.game_match.teams[teamIndex].lineup[playerIndex].card = 'y';
				}
				break;
			case 'second-yellow-card':
				if (gameMatchResponse?.game_match.teams[teamIndex].lineup[playerIndex]) {
					gameMatchResponse.game_match.teams[teamIndex].lineup[playerIndex].card = 'yr';
					gameMatchResponse.game_match.teams[teamIndex].lineup[playerIndex].on_field = false;
					setActionModalPlayer(gameMatchResponse?.game_match.teams[teamIndex].lineup[playerIndex]);
				}
				setActionModalTitle('EXPULSADO');
				setShowActionModal(true);
				break;
			case 'red-card':
				if (gameMatchResponse?.game_match.teams[teamIndex].lineup[playerIndex]) {
					gameMatchResponse.game_match.teams[teamIndex].lineup[playerIndex].card = 'r';
					gameMatchResponse.game_match.teams[teamIndex].lineup[playerIndex].on_field = false;
					setActionModalPlayer(gameMatchResponse?.game_match.teams[teamIndex].lineup[playerIndex]);
				}
				setActionModalTitle('EXPULSADO');
				setShowActionModal(true);
				break;
			case 'injury':
				if (gameMatchResponse?.game_match.teams[teamIndex].lineup[playerIndex]) {
					gameMatchResponse.game_match.teams[teamIndex].lineup[playerIndex].injury = true;
					setActionModalPlayer(gameMatchResponse?.game_match.teams[teamIndex].lineup[playerIndex]);
				}
				setActionModalTitle('LESIONADO');
				setShowActionModal(true);
				break;
			case 'substitution':
				const playerInIndex = action.team
					? gameMatchResponse?.game_match.teams[teamIndex].lineup.findIndex((player) => player.player === action.player_in) || 0
					: 0;
				if (gameMatchResponse?.game_match.teams[teamIndex].lineup[playerInIndex]) {
					gameMatchResponse.game_match.teams[teamIndex].lineup[playerInIndex].on_field = true;
					gameMatchResponse.game_match.teams[teamIndex].lineup[playerInIndex].position = action.position ?? 'XXX';
				}
				const playerOutIndex = action.team
					? gameMatchResponse?.game_match.teams[teamIndex].lineup.findIndex((player) => player.player === action.player_out) || 0
					: 0;
				if (gameMatchResponse?.game_match.teams[teamIndex].lineup[playerOutIndex]) {
					gameMatchResponse.game_match.teams[teamIndex].lineup[playerOutIndex].on_field = false;
					gameMatchResponse.game_match.teams[teamIndex].lineup[playerOutIndex].substitution = true;
				}
				console.log(gameMatchResponse?.game_match.teams[teamIndex]);
				break;
			case 'changetactic':
				if (
					gameMatchResponse?.game_match.teams[teamIndex]
				) {
					gameMatchResponse.game_match.teams[teamIndex].tactic = action.tactic ?? 'Desconocida';
				}
				break;
			case 'changeposition':
				if (
					gameMatchResponse?.game_match.teams[teamIndex].lineup[playerIndex]
				) {
					gameMatchResponse.game_match.teams[teamIndex].lineup[playerIndex].position = action.position ?? 'XXX';
				}
				break;
			case 'injurytime':
				updateMatchActionLine('TIEMPO ADICIONADO: ' + action.value + ' MINUTOS', undefined);
				break;
			case 'halftime':
				stopClock();
				updateMatchActionLine('FINAL DEL PRIMER TIEMPO', undefined);
				setMatchTime(45);
				setMatchHalf(2);
				break;
			case 'fulltime':
				stopClock();
				if (gameMatchResponse?.game_match.actions.filter((action) => action.half === 3).length) {
					updateMatchActionLine('DEFINICION POR PENALES', undefined);
					setIsPenaltyShootout(true);
					setMatchTime(90);
					setMatchHalf(3);
				} else {
					updateMatchActionLine('FINAL DEL PARTIDO', undefined);
					setIsMatchFinished(true);
				}
				break;
			case 'penalty-shootout-goal':
				if (teamIndex !== undefined && teamIndex !== -1) {
					setPenaltyShootoutScore((prevScore) => {
						const newScore = [...prevScore];
						newScore[teamIndex] = newScore[teamIndex] + 1;
						return newScore;
					});
				}
				break;
			case 'penalty-shootout-end':
				stopClock();
				updateMatchActionLine('FINAL DEL PARTIDO', undefined);
				setIsMatchFinished(true);
				break;
			default:
				alert('Se ha procesado una acción desconocida. Comunicarlo al líder supremo: Gato. Código de error: ' + action.type);
		  }
	};

	useEffect(() => {
		const handleClock = () => {
			if (isClockRunning && !isActionInProgressRef.current) {
				updateMatchActionLine('', undefined);
				setMatchTime((prevMinute) => prevMinute + 1);
			}
		};

		// Iniciar el intervalo del reloj
		if (isClockRunning && !intervalRef.current) {
			intervalRef.current = window.setInterval(handleClock, intervalTime);
		} else if (!isClockRunning && intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = undefined;
		}

		// Lógica para mostrar las acciones línea por línea cuando el minuto coincide.
	  	gameMatchResponse?.game_match.actions.forEach((action, index) => {
			if (
				isClockRunning &&	
				!isActionInProgressRef.current &&
				action.minute === matchTime &&
				action.half === matchHalf
			) {
				setIsPlayButtonDisabled(true);
				isActionInProgressRef.current = true;
				let i = 0;
				let currentIndex = index;
		  		let lines = gameMatchResponse?.game_match.actions[currentIndex].speach ?? [];
				let currentHalf = gameMatchResponse?.game_match.actions[currentIndex].half ?? 0;
  
		  		// Inicia un intervalo para mostrar cada línea de la acción.
		  		const actionInterval = setInterval(() => {
					if (i < lines.length) {
						updateMatchActionLine(lines[i], gameMatchResponse?.game_match.actions[currentIndex].team);
			  			i++;
					} else {
						processAction(gameMatchResponse?.game_match.actions[currentIndex]);
						if (
							gameMatchResponse?.game_match.actions[currentIndex + 1] &&
							gameMatchResponse?.game_match.actions[currentIndex + 1].minute === matchTime &&
							gameMatchResponse?.game_match.actions[currentIndex + 1].half === currentHalf
						) {
							lines = gameMatchResponse?.game_match.actions[currentIndex + 1].speach ?? [];
							i = 0;
							currentIndex++;
						} else {
							isActionInProgressRef.current = false;
							setIsPlayButtonDisabled(false);
							clearInterval(actionInterval);
						}
					}
		  		}, intervalTime * 2);
			}
	  	});

		// Limpia el intervalo cuando se desmonta el componente.
		return () => {
			if (intervalRef.current !== undefined) {
			  clearInterval(intervalRef.current);
			  intervalRef.current = undefined;
			}
		};
	}, [
		matchTime,
		isClockRunning,
		gameMatchResponse,
	]);

	useEffect(() => {
		if (matchId) {
			setIsLoading(true);
			reporitory
				.getGameMatch(matchId)
				.then((gameMatchResponse) => {
					const imageMap: ImageMap = {};
					gameMatchResponse.game_match.teams.forEach((team) => {
						team.lineup.forEach((player) => {
							const playerAvatar = document.createElement('img');
        					playerAvatar.src = player.avatar ?? "/img/avatar.png";
							playerAvatar.onload = () => {
								imageMap[player.player] = playerAvatar;
							};
						});
					});
					setGameMatchResponse(gameMatchResponse);
					setPlayersAvatar(imageMap);
					setIsLoading(false);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [reporitory, matchId]);

	if (isLoading) {
		return (
			<React.Fragment>
				<Loader />
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<Row className={styles.matchinforow}>
				<Col>
					<Stack>
						<Stack direction="horizontal">
							<Image
								src={'/img/ticket.png'}
								className={styles.matchinfoicon}
							/>
							<div className={styles.matchinfo}>
								{gameMatchResponse?.game_match.competition} - {gameMatchResponse?.game_match.instance}
							</div>
						</Stack>
						{gameMatchResponse?.game_match.stadium && (
							<Stack direction="horizontal">
								<Image
									src={'/img/map.png'}
									className={styles.matchinfoicon}
								/>
								<div className={styles.matchinfo}>{gameMatchResponse?.game_match.stadium}</div>
							</Stack>
						)}
					</Stack>
				</Col>
			</Row>
			{!textViewMode &&
				<React.Fragment>
					{gameMatchResponse?.game_match.teams.length ?
						<React.Fragment>
							<Row className={styles.actionrow}>
								<Col className={styles.timecol + ' d-flex align-items-center justify-content-center col-2'}>
									<Row className={styles.timerow}>
										{matchTime}:00
									</Row>
								</Col>
								<Col className={styles.timecol + ' col-10'}>
									{gameMatchResponse?.game_match.teams.map((team, index) => (
										<Row key={index}>
											<Col className="col-9">
												<div className={styles.teamtext + ' ' + styles.teamname}>
													{team.name}
												</div>
											</Col>
											<Col className="col-3">
												<div className={styles.teamtext + ' ' + styles.teamgoals}>
													{matchScore[index]}
													{isPenaltyShootout && (' (' + penaltyShootoutScore[index] + ')')}
												</div>
											</Col>
										</Row>
									))}
								</Col>
							</Row>
							<Row className={styles.actionrow}>
								{gameMatchResponse?.game_match.teams.map((team) => (
									<MatchTeamLineup team={team} key={team.code} />
								))}
							</Row>
							<Row className={styles.actionrow}>
								<Col
									style={ {backgroundColor: matchActionLineBackgroundColor} }
									className={styles.actioncol + ' d-flex align-items-center justify-content-center'}
								>
									<div
										style={ {color: matchActionLineTextColor} }
										className={styles.actiontext}
									>
										{ matchActionLine }
									</div>
								</Col>
							</Row>
							<Row>
								<Col className={styles.buttoncol + ' p-0 col-3 d-grid'}>
									<Button
										variant="primary"
										size="sm"
										onClick={decreaseSpeed}
										disabled={isPlayButtonDisabled || isMatchFinished || intervalTime >= 2200}
									>
										Vel -
									</Button>
								</Col>
								<Col className={styles.buttoncol + ' p-0 col-6 d-grid'}>
									<Button
										variant="primary"
										size="sm"
										onClick={isClockRunning ? stopClock : startClock}
										disabled={isPlayButtonDisabled || isMatchFinished}
									>
										{ isClockRunning
											? 'Detener'
											: matchTime == 0 ? 'Iniciar' : 'Reanudar'}
									</Button>
								</Col>
								<Col className={styles.buttoncol + ' p-0 col-3 d-grid'}>
									<Button
										variant="primary"
										size="sm"
										onClick={increaseSpeed}
										disabled={isPlayButtonDisabled || isMatchFinished || intervalTime <= 200}
									>
										Vel +
									</Button>
								</Col>
							</Row>
							<Modal
								centered
								show={showSpeedModal}
								animation={false}
								backdrop={false}
							>
								<Modal.Body>
									<ProgressBar animated now={speedPercentage} />
								</Modal.Body>
							</Modal>
							<Modal
								centered
								show={showActionModal}
								animation={false}
								backdrop={false}
							>
								<Modal.Body className="p-0">
									<Stack>
										<div className={styles.actionmodaltitle}>
											{actionModalTitle}
										</div>
										<Stack direction="horizontal" className="m-2">
											<Image
												src={actionModalPlayer?.avatar ?? "/img/avatar.png"}
												roundedCircle
												className={styles.actionmodalavatar}
												onError={({ currentTarget }) => {
													currentTarget.onerror = null;
													currentTarget.src="/img/avatar.png";
												}}
											/>
											<div className={styles.actionmodalname}>
												{actionModalPlayer?.name ?? ''} {actionModalPlayer?.surname ?? ''}
											</div>
										</Stack>
									</Stack>
								</Modal.Body>
							</Modal>
						</React.Fragment> :
						<div>El visor no se encuentra disponible para este partido. Utilice el modo texto.</div>
					}
				</React.Fragment>
			}
			{textViewMode && gameMatchResponse?.game_match.text.map((textLine, index) => (
				<div className={styles.textmode} key={index}>
					{textLine}
				</div>
			))}
			<Row>
				<Col className={styles.buttoncol + ' p-0 col-12 d-grid'}>
					<Button
						variant="primary"
						size="sm"
						onClick={switchViewMode}
					>
						Cambiar a modo { textViewMode ? 'visor' : 'texto' }
					</Button>
				</Col>
			</Row>
		</React.Fragment>
	);
}
