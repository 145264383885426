import {
	GameMatch,
	LamfoApiGameMatchResponse
} from "./LamfoApiResponses";

export class LamfoApiGameMatchRepository {
	private readonly apiUrl = process.env.REACT_APP_LAMFO_API_URL;
	private readonly getGameMatchEndpoint = "$apiUrl/public/match/$matchId";

	async getGameMatch(matchId: string): Promise<LamfoApiGameMatchResponse> {
		const repositoryRequest = fetch(
			this.getGameMatchEndpoint.replace("$apiUrl", this.apiUrl ?? "").replace("$matchId", matchId),
			{ headers: {} }
		);

		return repositoryRequest
			.then((response) => response.json())
			.then((game_match) => {
				return {
					game_match: game_match as GameMatch,
				};
			});
	}
}
