import React, { useContext } from "react";

import { EventsCalendar } from "../../components/eventsCalendar/EventsCalendar";
import UserContext from "../../contexts/UserContext";

export function Calendar() {
	const userData = useContext(UserContext);

	return (
		<React.Fragment>
			<h1 className="text-center mb-0 mt-2">CALENDARIO</h1>
			<EventsCalendar teamCode={userData.success ? userData.team.code : undefined} />
		</React.Fragment>
	);
}
