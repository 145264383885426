import {
	LamfoApiEmptyResponse,
	LamfoApiPlayersChangesResponse,
	Pagination,
	PlayerChange
} from "./LamfoApiResponses";

export class LamfoApiPlayerRepository {
	private readonly apiUrl = process.env.REACT_APP_LAMFO_API_URL;
	private readonly transferPriceEndpoint = "$apiUrl/private/player/transfer-price";
	private readonly changesEndpoint = "$apiUrl/public/players_changes";

	async setTransferPrice(playerCode: string, price: number): Promise<LamfoApiEmptyResponse> {
		const repositoryRequest = fetch(
			this.transferPriceEndpoint.replace("$apiUrl", this.apiUrl ?? ""),
			{
				method: "PUT",
				credentials: "include",
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ playerCode: playerCode, price: price }),
			}
		);

		return repositoryRequest
			.then((response) => response);
	}

	async deleteTransferPrice(playerCode: string): Promise<LamfoApiEmptyResponse> {
		const repositoryRequest = fetch(
			this.transferPriceEndpoint.replace("$apiUrl", this.apiUrl ?? ""),
			{
				method: "DELETE",
				credentials: "include",
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ playerCode: playerCode }),
			}
		);

		return repositoryRequest
			.then((response) => response);
	}

	async getPlayerChanges(teamCode: string, page: number): Promise<LamfoApiPlayersChangesResponse> {

		const apiEndpoint = this.changesEndpoint.replace("$apiUrl", this.apiUrl ?? "") + `?page=${page}` + (teamCode && `&team=${teamCode}`);

		const repositoryRequest = fetch(apiEndpoint);

		return repositoryRequest
			.then((response) => {
				if (!response.ok) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then((playersChanges) => {
				return {
					data: playersChanges.data as PlayerChange[],
					pagination: playersChanges.pagination as Pagination,
				};
			})
			.catch((error) => {
				throw error;
			});
	}
}
