import React from "react";

import { Col } from "react-bootstrap";
import Image from 'react-bootstrap/Image';
import styles from "./loader.module.scss";

export function Loader() {
    return (
        <React.Fragment>
            <Col className="text-center">
                <Image 
                    className={ styles.loaderimg }
                    src="/img/loader.gif"
                />
            </Col>
        </React.Fragment>
    );
}