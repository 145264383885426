import {
	Team,
	LamfoApiEconomyResumesResponse
} from "./LamfoApiResponses";

export class LamfoApiEconomyRepository {
	private readonly apiUrl = process.env.REACT_APP_LAMFO_API_URL;
	private readonly getEconomyResumesEndpoint = "$apiUrl/public/economy";

	async getTeamsEconomyResume(): Promise<LamfoApiEconomyResumesResponse> {
		const repositoryRequest = fetch(
			this.getEconomyResumesEndpoint.replace("$apiUrl", this.apiUrl ?? ""),
			{ headers: {} }
		);

		return repositoryRequest
			.then((response) => response.json())
			.then((divisions) => {
				return {
					pra: divisions['pra'] as Team[],
					pbn: divisions['pbn'] as Team[],
					prb: divisions['prb'] as Team[],
					prc: divisions['prc'] as Team[]
				};
			});
	}
}
