import {
	KeyDate,
	LamfoApiCalendarResponse
} from "./LamfoApiResponses";

export class LamfoApiCalendarRepository {
	private readonly apiUrl = process.env.REACT_APP_LAMFO_API_URL;
	private readonly getCalendarEndpoint = "$apiUrl/public/calendar";

	async getCalendar(teamCode?: string): Promise<LamfoApiCalendarResponse> {
		const repositoryRequest = fetch(
			this.getCalendarEndpoint.replace("$apiUrl", this.apiUrl ?? "") + (teamCode ? `?team=${teamCode}` : ''),
			{ headers: {} }
		);

		return repositoryRequest
			.then((response) => response.json())
			.then((calendarResponse) => {
				return {
					calendar: calendarResponse['calendar'] as KeyDate[]
				};
			});
	}
}
