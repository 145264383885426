import React from "react";

import { Player } from "../../infrastructure/LamfoApiResponses";
import { Badge, Card, Col, Image, Row } from "react-bootstrap";
import styles from "./playercardheader.module.scss";
import { PlayerField } from "../playerField/PlayerField";

export function PlayerCardHeader(props: {
    onClick: (event: React.MouseEvent<HTMLElement>) => void,
    teamCode: string,
    player: Player,
	isDetail?: boolean
}) {
    const { onClick, teamCode, player, isDetail } = props;

    return (
        <React.Fragment>
            <Card.Header 
				className={"p-1 " + (player.loan?.owner_team.code === teamCode
					? styles.cardheaderout
					: styles.cardheader)}
                    onClick={e => onClick(e)}
			>
				<Row>
					<Col className="col-3 p-0 text-center">
						<Image
							src={player.player_data.avatar ?? "/img/avatar.png"}
							roundedCircle
							className={
								styles.avatar + 
								((player.loan && player.loan.owner_team.code === teamCode)
									? ' ' + styles.avatarout : '')
							}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null;
								currentTarget.src="/img/avatar.png";
							}}
						/>
						{player.player_data.citizenships.map((citizenship, index) => (
							<Image
								key={index}
								src={citizenship.flag ?? '/img/question.png'}
								roundedCircle
								alt={index.toString()}
								className={styles.countryflag + ' ' + (index == 0 ? styles.countryflagfirst : index == 1 ? styles.countryflagsecond : styles.countryflagthird)}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null;
									currentTarget.src="/img/question.png";
								}}
							/>
						))}
					</Col>
					<Col className={
						'p-0 col-' +
						(isDetail ? '7' : '5') +
						(player.player_data.new || player.player_data.promoted ? ' ' + styles.colnamesnew : '')
					}>
						<div className={
							(
								(player.loan && player.loan.team.code === teamCode)
									? styles.playerloanedtext
									: styles.playertext
							)
							+ ' ' + styles.playersurname
						}>
							{
								isDetail
									? player.player_data.surname
									: player.player_data.short_surname + ' ' + player.player_data.short_name[0] + '.'
							}
						</div>
						<div className={
							(
								(player.loan && player.loan.team.code === teamCode)
									? styles.playerloanedtext
									: styles.playertext
							)
							+ ' ' + styles.playersurname
						}>
							{
								isDetail
									? player.player_data.name
									: 
										<React.Fragment>
											<div className={
												styles.energy + ' ' + 
												(player.stats.fitness > 90 ? styles.energyok
													: player.stats.fitness > 65 ? styles.energymedium
														: styles.energylow
												)
											}>
												{player.stats.fitness}
											</div>
											<div className={
												styles.energy + ' ' + 
												(player.stats.rithm > 90 ? styles.energyok
													: player.stats.rithm > 65 ? styles.energymedium
														: styles.energylow
												)
											}>
												{player.stats.rithm}
											</div>
											<Image
												src={'/img/moral_' + player?.stats.happyness + '.png'}
												className={styles.staticon}
											/>
											{player.stats.suspended_matches > 0 ?
												<div className={styles.card + ' ' + styles.redcard} /> : ''
											}
											{player.stats.suspended_matches == 0 && player.stats.yellow_cards == 4 ?
												<div className={styles.card + ' ' + styles.yellowcard} /> : ''
											}
											{player.stats.injury_weeks > 0 ?
												<Image
													src="/img/pharmacy.png"
													className={styles.staticon}
												/> : ''
											}
											{(player.contract?.termination_clause ?? 0) ?
												<Image
												src="/img/clause.png"
												className={styles.staticon}
											/> : ''
											}
											{(player.player_data?.transfer_price ?? 0) ?
												<Image
												src="/img/sale.png"
												className={styles.staticon}
											/> : ''
											}
										</React.Fragment>
							}
							
						</div>
					</Col>
					<Col className="col-2 p-0">
						<PlayerField position={player.position} sides={player.sides} />
					</Col>
					{
						isDetail
							? ''
							:
							<Col className="col-2 p-0">
								<div className={styles.cardheadmainskill}>
									{
										{
											'GK': player.skills.gk,
											'DF': player.skills.tk,
											'MF': player.skills.ps,
											'FW': player.skills.sh,
										}[player.position]
									}
								</div>
							</Col>
					}
				</Row>			
			</Card.Header>
        </React.Fragment>
    );
}