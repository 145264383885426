import React from "react";

import Image from 'react-bootstrap/Image';
import styles from "./playerfield.module.scss";

export function PlayerField(props: {
	position: string,
    sides: string[]
}) {
    const { position, sides } = props;

    return (
        <React.Fragment>
            <div className={styles.field}>
                {sides.map((side, index) => (
                    <div
                        key={index}
                        className={
                            styles.pos + ' ' + 
                            {
                                'GK': styles.posgk,
                                'DF': styles.posdf,
                                'MF': styles.posmf,
                                'FW': styles.posfw,
                            }[position] + ' ' +
                            {
                                'R': styles.posr,
                                'C': styles.posc,
                                'L': styles.posl,
                            }[side]
                        }
                    ></div>
                ))}
                <Image 
                    className={styles.fieldimage}
                    src="/img/field.png" 
                />
            </div>
        </React.Fragment>
    );
}