import React, { useEffect, useState } from "react";

import styles from "./home.module.scss";

export function Home() {
	return (
		<React.Fragment>
			<div className="text-center mt-3">Proximamente</div>
		</React.Fragment>
	);
}
