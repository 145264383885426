import React, { useContext, useEffect, useState } from "react";
import { LamfoApiCalendarResponse } from "../../infrastructure/LamfoApiResponses";
import { createEmptyCalendarReponse } from "../../infrastructure/LamfoEmptyThings";
import { LamfoApiCalendarRepository } from "../../infrastructure/LamfoApiCalendarRepository";
import { Image, ListGroup } from "react-bootstrap";
import { FaCalendarAlt, FaFile, FaFileAlt, FaFileContract, FaFutbol, FaRegFile } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import styles from "./eventscalendar.module.scss";
import { Link } from "react-router-dom";

const repository = new LamfoApiCalendarRepository();

interface EventsCalendarProps {
    teamCode?: string;
    showOnlyWithMatch?: boolean;
}

export function EventsCalendar({
    teamCode = undefined,
    showOnlyWithMatch = false,
}: EventsCalendarProps) {
    const [calendarResponse, setCalendarResponse] = useState<LamfoApiCalendarResponse>(createEmptyCalendarReponse());
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        repository
            .getCalendar(teamCode)
            .then((calendarApiResponse) => {
                setCalendarResponse(calendarApiResponse);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [repository, teamCode]);

    return (
        <React.Fragment>
            <ListGroup as="ol" className={styles.keydateitems}>
                {isLoading && Array.from({ length: 6 }).map((_, index) => (
                    <ListGroup.Item as="li" key={index}>
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">
                                <FaCalendarAlt className="me-1" />
                                <Skeleton width={100} />
                            </div>
                            {Array.from({ length: 4 }).map((_, index) => (
                                <div key={index} className={styles.keydateevent}>
                                    <Skeleton width={200} />
                                </div>
                            ))}
                        </div>
                    </ListGroup.Item>
                ))}
                {!isLoading && calendarResponse.calendar.map((date, index) => {
                    const filteredEvents = date.events.filter(event => !showOnlyWithMatch || event.team_match);
                    
                    if (showOnlyWithMatch && filteredEvents.length === 0) {
                        return null;
                    }

                    return (
                        <ListGroup.Item as="li" key={index}>
                            <div className="ms-2 me-auto">
                                <div className="fw-bold text-capitalize d-flex align-items-center">
                                    <FaCalendarAlt className="me-1" style={{ fontSize: '1.2em', verticalAlign: 'middle' }} />
                                    {new Date(date.date).toLocaleDateString('es-ES', { weekday: 'long', day: '2-digit', month: '2-digit' })}
                                </div>
                                {filteredEvents.map((event, eventIndex) => (
                                    <div key={eventIndex}>
                                        <div className={styles.keydateevent + ' ml-1 d-flex align-items-center'}>
                                            {event.type == 'competition' ? 
                                                <FaFutbol className="me-1" style={{ fontSize: '1.2em', verticalAlign: 'middle' }} /> :
                                                <FaFileAlt className="me-1" />
                                            }
                                            {event.description}
                                        </div>
                                        {event.team_match && (
                                            <Link to={`/team/${event.team_match.rival.code}`}>
                                                <div className={styles.eventmatch}>
                                                    <Image
                                                        src={event.team_match.rival.shield}
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null;
                                                            currentTarget.src="/img/shield.png";
                                                        }}
                                                    />
                                                    {event.team_match.rival.name}
                                                    {event.team_match.field === "home" ? " (L)" : (event.team_match.field === "away" ? " (V)" : " (N)")}
                                                </div>
                                            </Link>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
        </React.Fragment>
    );
}