import { Action, LamfoApiPlayersChangesResponse, LamfoApiUserNotificationsResponse, Pagination, Player } from "./LamfoApiResponses";

export const createEmptyPlayer = (): Player => ({
    code: '',
    position: '',
    sides: [],
    skills: {
        gk: 0,
        tk: 0,
        ps: 0,
        sh: 0
    },
    player_data: {
        name: '',
        short_name: '',
        surname: '',
        short_surname: '',
        avatar: '',
        citizenships: [],
        new: false,
        promoted: false,
        former_team: '',
        transfer_price: undefined,
    },
    age: 0,
    stats: {
        minutes: 0,
        saves: 0,
        goals: 0,
        assistances: 0,
        injury_weeks: 0,
        suspended_matches: 0,
        fitness: 0,
        yellow_cards: 0,
        happyness: 0,
        rithm: 0,
    },
    actions: [],
});

export const createEmptyAction = (): Action => ({
    label: '',
	confirmation: false,
});

export const createEmptyPlayersChangesResponse = (): LamfoApiPlayersChangesResponse => ({
    data: [],
    pagination: createEmptyPagination(),
});

export const createEmptyUserNotificationsResponse = (): LamfoApiUserNotificationsResponse => ({
    data: [],
    pagination: createEmptyPagination(),
    total_new: 0,
});

export const createEmptyPagination = (): Pagination => ({
    page: 0,
    per_page_items: 0,
    total_items: 0,
    total_pages: 0,
});

export const createEmptyCalendarReponse = () => ({
    calendar: [],
});

export const createEmptyRankingReponse = () => ({
    ranking: [],
});

