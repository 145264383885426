import React from "react";

import { GameMatchTeam } from "../../infrastructure/LamfoApiResponses";
import { Badge, Col, Image, Stack } from "react-bootstrap";
import styles from "./match.module.scss";

export function MatchTeamLineup(props: {
    team: GameMatchTeam
}) {
    const { team } = props;

    return (
        <React.Fragment>
            <Col className="p-0">
				<Stack>
					{team.lineup.map((player, index) => (
						<Stack direction="horizontal" key={index} className={index == 10 ? 'mb-1' : ''}>
							<Badge className={styles.playerposition} bg={player.on_field ? 'primary' : 'secondary'}>
								{player.position}
							</Badge>
							<div className={styles.playername + (!player.on_field ? ' ' + styles.substitutename : '')}>{player.name ? player.name[0] + '. ' : ''}{player.surname}</div>
							{
								player.goals > 0 && <div className={styles.actionnumber}>
									{player.goals > 1 && player.goals}
								</div>
							}
							{
								{
									'y': <div className={styles.card + ' ' + styles.yellowcard} />,
									'yr': <Stack direction="horizontal">
											<div className={styles.card + ' ' + styles.yellowcard} />
											<div className={styles.card + ' ' + styles.redcard} />
										  </Stack>,
									'r': <div className={styles.card + ' ' + styles.redcard} />,
								}[player.card]
							}
							{
								player.injury && <Image
									src="/img/pharmacy.png"
									className={styles.actionicon}
								/>
							}
							{
								player.substitution && <Image
									src="/img/swap.png"
									className={styles.actionicon}
								/>
							}
						</Stack>
					))}
					<Stack direction="horizontal">
						<Image
							src={'/img/coach.png'}
							className={styles.lineupinfoicon}
						/>
						<div className={styles.playername}>{team.manager}</div>
					</Stack>
					<Stack direction="horizontal">
						<Image
							src={'/img/strategy.png'}
							className={styles.lineupinfoicon}
						/>
						<div className={styles.playername}>{team.tactic}</div>
					</Stack>
				</Stack>
			</Col>
        </React.Fragment>
    );
}